export type ScanStatus = 'cancelled' | 'error' | 'pending' | 'success' | null

export const CANCELLED: ScanStatus = 'cancelled'
export const ERROR: ScanStatus = 'error'
export const PENDING: ScanStatus = 'pending'
export const SUCCESS: ScanStatus = 'success'

export default function useScanStatus() {
  const scanStatus = ref<ScanStatus>(null)

  return {
    CANCELLED,
    ERROR,
    PENDING,
    SUCCESS,
    scanStatus,
  }
}
