<template>
  <span v-if="status" class="d-inline-block px-2 qtm-body text-no-wrap" :class="colors">
    <v-tooltip activator="parent" :disabled="disabled || status !== ERROR">
      We couldn't scan your <span class="text-lowercase" v-text="attachmentLabel" />. Please add order items manually.
    </v-tooltip>
    <v-icon :color="iconColor">{{ icon }}</v-icon> {{ text }}
  </span>
</template>

<script setup lang="ts">
import {
  CANCELLED,
  ERROR,
  PENDING,
  SUCCESS,
  type ScanStatus,
} from '@/composables/scan-status'

export interface Props {
  attachmentLabel?: string
  disabled?: boolean
  status?: ScanStatus
}

const props = defineProps<Props>()

const negative = computed(() => props.status === CANCELLED || props.status === ERROR)
const colors = computed(() => {
  if (negative.value) {
    return ['bg-light-grey', 'text-dark-grey']
  }

  return ['bg-light-teal', 'text-secondary']
})
const icon = computed(() => {
  switch (props.status) {
    case PENDING:
      return 'mdi-text-box-search-outline'
    case SUCCESS:
      return 'mdi-check-underline'
    default:
      return 'mdi-information-outline'
  }
})
const iconColor = computed(() => {
  if (negative.value) {
    return 'error'
  }
  return 'interactive'
})
const text = computed(() => {
  switch (props.status) {
    case CANCELLED:
      return 'Scan cancelled'
    case ERROR:
      return 'Scan failed'
    case PENDING:
      return 'Scanning...'
    default:
      return 'Scanned'
  }
})
</script>
